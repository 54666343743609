import React, { useEffect, useState, useRef, useContext, } from "react";
import Tippy from "@tippyjs/react";
import { certhubUrl, hostEventUrl, mediaUrl, quizhubUrl } from "../../config/config";
import { clickOutside, } from "../../helpers/utils";
import Link from "next/link";
import AuthContext from "../../contexts/AuthContext";
import { Auth } from "aws-amplify";
import Loading from "../Loading";
import Dashboard from '../../../public/img/profile-dropdown/Dashboard Layout.svg'
import Logout from '../../../public/img/profile-dropdown/Enter.svg'
import Refer from '../../../public/img/profile-dropdown/Share.svg'
import User from '../../../public/img/profile-dropdown/User.svg'
const ProfileDropDown = ({ data, onLogout, hideProfile, className }) => {


    const wrapperRef = useRef("menu");
    const [open, setOpen] = useState(false);
    const [user, setUser] = useState({});
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(false);
    const authContext = useContext(AuthContext)
    const currentUser = async () => {
        setLoading(true);
        const _usr = await Auth.currentUserInfo();
        setUser(_usr.attributes)
        setLoading(false);
    }

    function handleError() {
        setIsError(true);
    }

    useEffect(() => {
        if (authContext.signedIn) {
            currentUser();
        }
    }, [data || null])


    clickOutside(wrapperRef, () => {
        setOpen(false);
    });


    return (
        <>
            {
                loading ?
                    <Loading className={'m-auto'} color="#002e6e" size={"42px"} />
                    :
                    <div className={`profile-container position-relative ml-3 mb-0 mb-sm-2 ${className ? className : ""}`}>

                        {open ? <span className="arrow-up" /> : ""}

                        <div ref={wrapperRef} className="profile-img-container d-inline-block">
                            <div className="d-flex align-items-center h-100 w-100 position-relative">


                                {!isError && user["custom:profilePicture"] ? <img className="col-12" src={user["custom:profilePicture"]} onError={handleError} alt={'Profile Picture'} /> :
                                    <img className="col-12" src={`${mediaUrl}/default-profile.png`} alt={'Profile Picture'} />
                                }
                                {!open ? <Tippy content={user?.email}>
                                    <button onClick={() => setOpen(!open)} className="profile-overlay h-100 w-100 position-absolute"></button>
                                </Tippy> : <button onClick={() => setOpen(!open)} className="profile-overlay h-100 w-100 position-absolute"></button>}


                            </div>
                            {open && (
                                <div className="profile-dropDown-container p-2 position-absolute">
                                    <div className="d-none d-md-flex d-lg-flex px-1 py-2 text-left switchEvent-pad">
                                        <div className=" pr-0">
                                            {
                                                isError ?
                                                    <img className="profile-dropDown-img" src={`${mediaUrl}/default-profile.png`} alt='Propfile P' />
                                                    :
                                                    <img className="profile-dropDown-img" src={user["custom:profilePicture"]} alt='Propfile P' />
                                            }
                                        </div>
                                        <div className=" details-container ms-2 pl-0 pr-0 mt-auto mb-auto">
                                            <p className="h5 profile-email mb-1">{user?.name}</p>
                                            <p className="profile-sub-text mb-0">{user?.email}</p>
                                        </div>
                                    </div>
                                    <div className="profile-divider d-none d-md-flex d-lg-flex mb-2 mt-0 mx-auto"></div>
                                    <div className="profile-dropdown-options text-left">
                                        <a href={`${hostEventUrl}`} target="_blank"><span className="d-flex align-items-center"> <img src={Dashboard} className="me-2" alt="" /> Dashboard</span> </a>
                                        {!hideProfile && <Link href='/profile'><span className="d-flex align-items-center"><img src={User} className="me-2" alt="" />  Manage Profile</span></Link>}
                                        {/* {<Link href='/profile'><span className="d-flex align-items-center"><img src={Refer} className="me-2" alt="" /> Refer & Earn</span></Link>} */}
                                        {/* <a href={`${certhubUrl}`} target="_blank">CertHub</a> */}
                                        {/* <a href={`${quizhubUrl}`} target="_blank">QuizHub</a> */}
                                    </div>
                                    <div className="profile-divider d-none d-md-flex d-lg-flex mt-2 mb-1 mt-0 mx-auto"></div>
                                    <div className="profile-dropdown-options">
                                        <span className="d-flex align-items-center" style={{ color: "#002e6e" }} onClick={onLogout} ><img src="https://d2nnrpx7gk2my2.cloudfront.net/files/672c650d97b04e000924da19/Logout.svg" className="me-2" alt="" /> Logout</span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <style>
                            {`
                        .profile-container {
                            height: 40px;
                        }
                        .profile-img-container {
                            height: 42px;
                            width: 42px;
                            border-radius: 100%;
                            overflow: hidden;
                            // border: 1px solid #fff;
                            background: #fff;
                            box-shadow:1px 1px 10px  #0002;
                        }

                        .profile-overlay {
                            display:none;
                            top: 0;
                            background: transparent;
                            opacity: 0.3;
                            border-radius: 100%;
                            border: 1px solid transparent;
                        }

                        .profile-img-container:hover .profile-overlay {
                            display:block;
                            cursor: pointer;
                        }

                        .arrow-up {
                            width: 0; 
                            height: 0; 
                            margin-top: -50px !important;
                            border-left: 8px solid transparent;
                            border-right: 8px solid transparent;
                            position: absolute;
                            top: 87px;
                            right: 12px;
                            border-bottom: 16px solid white;
                        }
                        .profile-dropDown-container {
                            z-index: 1030;
                            min-width: 290px;
                            max-width: 290px;
                            background: #fff;
                            right: 0px;
                            border-radius: 3px;
                            top: 48px;
                            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
                        }

                        .profile-dropDown-img {
                            height: 42px;
                            width: 42px;
                            border-radius: 100%;
                        }
                        .details-container *{
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                        }

                        .profile-sub-text {
                            font-family: 'Nunito';
                            font-style: normal;
                            font-weight: 500;                   
                            font-size: 16px;
                            max-width: 200px;
                            line-height: 16px;
                            /* identical to box height, or 133% */
                            color: #808080;
                        }

                        .profile-email {
                            font-family: 'Nunito';
                            font-style: normal;
                            font-weight: 600;
                            max-width: 120px;
                            font-size: 22px;
                            line-height: 22px;
                            /* or 114% */
                            color: #2D2D2D;
                        }

                        .profile-divider {
                            width: 100%;
                            border-bottom: 0.5px solid #C3CDDF;
                        }

                        .profile-dropdown-options > * {
                            display: block;
                            width: 100%;
                            padding: 8px 0px 8px 6px;
                            border-radius:2px;
                            font-size: 16px;
                            color: #2D2D2D;
                            font-family: Nunito;
                            font-weight: 500;
                            line-height: 16px;
                            letter-spacing: 0em;
                            text-align: left;
                            text-decoration: none;
                        }

                        .profile-dropdown-options > *:hover {
                            background: #80747C33;
                            color: #2D2D2D;
                            cursor:pointer
                        }

                        .switchEvent-pad {
                            padding-bottom: 0.8rem !important;
                        }
                        `}
                        </style>
                    </div>
            }
        </>

    );
}

export default ProfileDropDown;